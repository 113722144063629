import React from 'react';
import { Box, Flex, Heading, Text, useColorModeValue, Link, IconButton, Center, VStack } from '@chakra-ui/react';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Footer from './Footer';

const MotionBox = motion(Box);

const Contact = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('blue.700', 'white');
  const borderColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <>
      <Box id="contact" bg={bgColor} color={textColor} py={20}>
        <Flex direction="column" align="center" maxW="800px" mx="auto" px={5} mt={16}>
          <Heading as="h3" size="xl" mb={10} borderBottom="4px solid" borderColor={borderColor} pb={2}>
            Contact
          </Heading>
          <MotionBox
            p={5}
            bg={useColorModeValue('white', 'gray.800')}
            borderRadius="md"
            boxShadow="lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            textAlign="center"
            mb={10}
          >
            <Text fontSize="xl" mb={5}>
              If you have any questions or inquiries, feel free to contact me via email at:
            </Text>
            <Link href="mailto:jawoyomi@cengpcs.com" color={textColor} _hover={{ textDecoration: 'underline', color: 'blue.500' }} fontSize="2xl">
              <IconButton
                aria-label="Send email"
                icon={<FaEnvelope />}
                size="lg"
                variant="ghost"
                colorScheme="blue"
                mr={2}
              />
              jawoyomi@cengpcs.com
            </Link>
          </MotionBox>
          <VStack spacing={8}>
            <Box textAlign="center">
              <Heading as="h4" size="md" mb={4}>
                Company Location
              </Heading>
              <Center>
                <Box
                  as="iframe"
                  width="600px"
                  height="400px"
                  borderRadius="md"
                  boxShadow="lg"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31713.276646507684!2d3.5086!3d6.4333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf4f2dfcd33ab%3A0x9f0d3b5e76a927d0!2sThe%20Morning%20Dew%20Court%2C%2025%20Yemi%20Adetayo%20St%2C%20Ilasan%2C%20Lekki%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sng!4v1623686824112!5m2!1sen!2sng"
                  allowFullScreen=""
                  loading="lazy"
                ></Box>
              </Center>
            </Box>
            <Box textAlign="center">
              {/* <Text fontSize="xl">
                
              </Text> */}
              <Text fontSize="lg" mt={2}>
                <FaMapMarkerAlt /> Flat 1a, The Morning Dew Court, 25 Yemi Adetayo Street, Ilasan, Lekki, Lagos, Nigeria
              </Text>
            </Box>
          </VStack>
        </Flex>
      </Box>
      <Footer />
    </>
  );
};

export default Contact;
